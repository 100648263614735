define("bito-shop-frontend/templates/user-confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NGkAWd/C",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"panel pattern--gray\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"grid grid--max\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"headline-group text-center grid__col-24\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"account.confirm\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"pattern--horizon\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"unit-wrapper\"],[12],[2,\"\\n    \"],[8,\"account-login-menu\",[],[[],[]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"unit unit--main\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"account\"],[12],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"grid grid--nested grid--max\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"grid__col-24\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"case\"],[12],[2,\"\\n              \"],[8,\"confirm-user\",[],[[\"@token\",\"@onSuccess\"],[[34,1,[\"token\"]],[30,[36,2],[\"onSuccess\"],null]]],null],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"route-action\"]}",
    "moduleName": "bito-shop-frontend/templates/user-confirmation.hbs"
  });

  _exports.default = _default;
});