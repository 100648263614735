define("bito-shop-frontend/services/view-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.defaults = {
        renderBreadcrumbs: true
      };
    },
    loadingOverlay: false
  });

  _exports.default = _default;
});