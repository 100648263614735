define("bito-shop-frontend/routes/index", ["exports", "bito-shop-frontend/routes/base-route", "fetch"], function (_exports, _baseRoute, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _baseRoute.default.extend({
    mystique: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    prudsys: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.viewState = {
        renderBreadcrumbs: false
      };
    },
    title: function title() {
      if (!this.mystique.tenantConfig.customTitle) return this.intl.t('home.title');
      var currentLocale = this.mystique.get('currentLocale');
      return this.mystique.tenantConfig.customTitle[currentLocale];
    },
    hreflangLinks: function hreflangLinks(model) {
      return model.hreflangLinks;
    },
    metaDescription: function metaDescription() {
      if (!this.mystique.tenantConfig.customMetaDescription) return this.intl.t('home.description');
      var currentLocale = this.mystique.get('currentLocale');
      return this.mystique.tenantConfig.customMetaDescription[currentLocale];
    },
    svcName: Ember.computed.reads('prudsys.client.serviceList.START_PAGE'),
    useRde: Ember.computed('svcName', function () {
      return this.prudsys.isPrudsysActive(this.svcName);
    }),
    model: function model() {
      var _this = this;

      var model = {};
      model.useRde = this.useRde;
      model.svcName = this.svcName; // used by rde to fetch products in top categories

      model.topCategories = this.store.findRecord('taxon-list', 'top_categories'); // data to render fallback content if rde is disabled

      if (!model.useRde) {
        model.otherMainCategories = this.store.findRecord('taxon-list', 'other_main_categories');
        model.topSeller = this.store.findRecord('variant_list', 'top_sellers');
      } // weekly changing tile products


      var date = new Date();
      var startOfYear = new Date(date.getFullYear(), 0, 1);
      var pastDaysOfYear = (date - startOfYear) / 86400000;
      var weeknumber = Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
      var weekVariantStartIndex = weeknumber % 6 * 2;
      var stepIds = ['11124', // Mehrwegbehälter MB
      '1444', // Regalkästen RK
      '1449', // Sichtlagerkasten SK
      '18498', // Eurostapelbehälter XL
      '773405', // Fachbodenregale Typ EL
      '21216', // Tischwagen mit Holzböden
      '3040413', // KÄRCHER Hochdruckreiniger HD 5/11 P Plus
      '47869', // Material- und Werkzeugschränke
      '13837', // Aluminium-Stehleitern
      '18039', // Kragarmregale Typ L
      '3040390', // KÄRCHER Nass-/Trockensauger NT 22/1 Ap L
      '43527' // Sackkarren
      ]; // use static products for de-de

      var weekVariants = [stepIds[weekVariantStartIndex], stepIds[weekVariantStartIndex + 1]].concat(_toConsumableArray(stepIds.slice(0, weekVariantStartIndex)), _toConsumableArray(weekVariantStartIndex + 2 < stepIds.length ? stepIds.slice(weekVariantStartIndex + 2) : []));
      model.tileProducts = this.store.query('variant', {
        step: true,
        ids: weekVariants
      }).then(function (variants) {
        return Promise.all(variants.map(function (variant) {
          return variant.get('taxon').promise.then(function () {
            return variant;
          });
        }));
      }).then(function (results) {
        return results.sort(function (a, b) {
          var stepIdIndexA = weekVariants.findIndex(function (stepId) {
            return a.stepId === stepId;
          });
          var stepIdIndexB = weekVariants.findIndex(function (stepId) {
            return b.stepId === stepId;
          });
          if (stepIdIndexA > stepIdIndexB) return 1;else if (stepIdIndexA < stepIdIndexB) return -1;else return 0;
        }).filter(function (result) {
          return result !== null;
        }).slice(0, 2);
      }).catch(function () {
        return null;
      });

      if (this.mystique.get('fastboot.isFastBoot')) {
        // preload megadeal in fastboot, spa loads data in megadeal component
        model.megaDeals = this.mystique.store.findAll('megaDeal'); // eager load relations, and null out when done

        model._fastBoot = Ember.RSVP.all([model.topCategories.then(function (r) {
          return r.get('taxons');
        }), // topSellers are only set if rde is disabled
        model.topSeller ? model.topSeller.then(function (r) {
          return r.get('variants');
        }) : Promise.resolve()]).then(function () {
          return null;
        });
      }

      model.hreflangLinks = this.mystique.tenantConfig.availableLocales.map(function (locale) {
        return {
          locale: locale,
          link: _this.mystique.baseUrl() + '/' + locale.toLowerCase() + '/'
        };
      });
      model.hreflangLinks.push({
        locale: 'x-default',
        link: this.mystique.baseUrl() + '/'
      });

      if (this.mystique.tenantConfig.hasStaticStartPageWelcome) {
        var welcomePage = this.store.peekRecord('page', 'welcome');
        model.welcomePage = Promise.resolve(welcomePage).then(function (welcomePage) {
          if (welcomePage) return welcomePage;
          var assetRootUrl = _this.mystique.env.assetRootUrl;
          var _this$mystique = _this.mystique,
              currentLocale = _this$mystique.currentLocale,
              currentRegion = _this$mystique.currentRegion;
          var staticWelcomePageUrl = "".concat(assetRootUrl, "assets/static-content/").concat(currentLocale, "/").concat(currentRegion, "/welcome.html");
          return (0, _fetch.default)(staticWelcomePageUrl).then(function (res) {
            return res.text();
          }).then(function (content) {
            _this.store.pushPayload({
              page: {
                id: 'welcome',
                sidebar: '',
                type: 'page',
                header: '',
                content: content
              }
            });
          }).then(function () {
            return _this.store.peekRecord('page', 'welcome');
          });
        });
      } else {
        model.welcomePage = this.store.findRecord('page', 'welcome').catch(console.warn);
      }

      return Ember.RSVP.hash(model);
    }
  });

  _exports.default = _default;
});