define("bito-shop-frontend/routes/account/address/email-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    breadCrumb: Ember.computed(function () {
      return {
        title: this.get('intl').t('account.editEmail')
      };
    }),
    titleToken: Ember.computed(function () {
      return this.get('intl').t('account.editEmail');
    }),
    model: function model() {
      var user = this.mystique.get('currentUser') || {};
      return {
        user: user
      };
    },
    actions: {
      onSave: function onSave() {
        // reload so we can show that the email was requested to change/show the new email the user wants to have
        var user = this.mystique.get('currentUser') || {};
        user === null || user === void 0 ? void 0 : user.reload();
        this.transitionTo('account.address');
      }
    }
  });

  _exports.default = _default;
});