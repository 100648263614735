define("bito-shop-frontend/mixins/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    addToCartNotification: Ember.inject.service(),
    // Notifications
    addToCartSuccessNotify: Ember.on('didAddToCart', function (lineItem) {
      this.addToCartNotification.show(lineItem);
    }),
    addToCartFailedNotify: Ember.on('addToCartFailed', function () {
      this.get('toast').error(this.get('intl').t('notifications.addToCartFailed'));
    }),
    addItemsToCartSuccessNotify: Ember.on('didAddLineItemsToCart', function (count) {
      this.get('toast').success(this.get('intl').t('notifications.addItemsToCartSuccess', {
        count: count
      }));
    }),
    addItemsToCartFailedNotify: Ember.on('addLineItemsToCartFailed', function (lineItems) {
      this.get('toast').error(this.get('intl').t('notifications.addItemsToCartFailed', {
        count: lineItems.length
      }));
    }),
    removeFromCartSuccessNotify: Ember.on('didRemoveFromCart', function () {
      this.get('toast').success(this.get('intl').t('notifications.removeFromCartSuccess'));
    }),
    removeFromCartFailedNotify: Ember.on('removeFromCartFailed', function () {
      this.toastGenericError();
    }),
    didSaveAddressNotify: Ember.on('didSaveAddress', function () {
      this.get('toast').success(this.get('intl').t('notifications.didSaveAddress'));
    }),
    didSaveEmailNotify: Ember.on('didSaveEmail', function () {
      this.get('toast').success(this.get('intl').t('notifications.didSaveEmail'));
    }),
    saveAddressFailedNotify: Ember.on('saveAddressFailed', function () {
      this.get('toast').error(this.get('intl').t('notifications.saveAddressFailed'));
    }),
    didDeleteAddressNotify: Ember.on('didDeleteAddress', function () {
      this.get('toast').success(this.get('intl').t('notifications.didDeleteAddress'));
    }),
    deleteAddressFailedNotify: Ember.on('deleteAddressFailed', function () {
      this.get('toast').error(this.get('intl').t('notifications.deleteAddressFailed'));
    }),
    addToWishlistSuccessNotify: Ember.on('didAddToWishlist', function (variant) {
      this.get('toast').success(this.get('intl').t('notifications.addToWishlistSuccess', {
        variant: variant.get('name')
      }));
    }),
    addToWishlistFailedNotify: Ember.on('addToWishlistFailed', function () {
      this.get('toast').error(this.get('intl').t('notifications.addToWishlistFailed'));
    }),
    removeFromWishlistSuccessNotify: Ember.on('removeFromWishlistSuccess', function () {
      this.get('toast').success(this.get('intl').t('notifications.removeFromWishlistSuccess'));
    }),
    removeFromWishlistFailedNotify: Ember.on('removeFromWishlistFailed', function () {
      this.toastGenericError();
    }),
    variantOptionsDidChangeNotify: Ember.on('variantOptionsDidChange', function () {
      this.get('toast').warning(this.get('intl').t('notifications.variantOptionsDidChange'));
    })
  });

  _exports.default = _default;
});