define("bito-shop-frontend/components/single-configurator-item-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#link-to "products" item.slug item.productId variantId
              invokeAction=(action "configuratorItemClick") class="single nav-teaser"}}
  
      <div class="nav-teaser__head">
        <div class="nav-teaser__title">
          <h4 class="u-no-margin-top"><span class="u-labeltag">Neu</span> Jetzt Regal in Ihrer Wunschlänge konfigurieren</h4>
        </div>
        <ProductImage @image={{item.image}}
                      @wrapperClass=""
                      @width="215"
                      @height="130"
                      @format="jpeg"
                      @quality="75"
                      @chromasub="444" />
      </div>
  
      <div class="single__content">
        <div class="single__info">
          <h2 class="single__name">{{item.name}}</h2>
          <p class="single__variants">({{t "taxons.hasCountVariants" count=variantCount}})</p>
        </div>
  
        <button type="button" class="btn btn__green paragraph">
          <span>
            <XIcon @id="cube" @class="u-margin-right" />
            {{t "configurators.configure"}}
          </span>
        </button>
      </div>
  {{/link-to}}
  
  */
  {
    "id": "DIno7lLw",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],null,[[\"invokeAction\",\"class\",\"route\",\"models\"],[[30,[36,5],[[32,0],\"configuratorItemClick\"],null],\"single nav-teaser\",\"products\",[30,[36,4],[[35,0,[\"slug\"]],[35,0,[\"productId\"]],[35,3]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"nav-teaser__head\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"nav-teaser__title\"],[12],[2,\"\\n        \"],[10,\"h4\"],[14,0,\"u-no-margin-top\"],[12],[10,\"span\"],[14,0,\"u-labeltag\"],[12],[2,\"Neu\"],[13],[2,\" Jetzt Regal in Ihrer Wunschlänge konfigurieren\"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"product-image\",[],[[\"@image\",\"@wrapperClass\",\"@width\",\"@height\",\"@format\",\"@quality\",\"@chromasub\"],[[34,0,[\"image\"]],\"\",\"215\",\"130\",\"jpeg\",\"75\",\"444\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"single__content\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"single__info\"],[12],[2,\"\\n        \"],[10,\"h2\"],[14,0,\"single__name\"],[12],[1,[35,0,[\"name\"]]],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"single__variants\"],[12],[2,\"(\"],[1,[30,[36,2],[\"taxons.hasCountVariants\"],[[\"count\"],[[35,1]]]]],[2,\")\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"button\"],[14,0,\"btn btn__green paragraph\"],[14,4,\"button\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"\\n          \"],[8,\"x-icon\",[],[[\"@id\",\"@class\"],[\"cube\",\"u-margin-right\"]],null],[2,\"\\n          \"],[1,[30,[36,2],[\"configurators.configure\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"item\",\"variantCount\",\"t\",\"variantId\",\"array\",\"action\",\"link-to\"]}",
    "moduleName": "bito-shop-frontend/components/single-configurator-item-new.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    gtm: Ember.inject.service(),
    variantCount: Ember.computed('item.variantCount', 'item.optionValues.[]', function () {
      return this.get('item.variantCount') || this.get('item.optionValues.length');
    }),
    variantId: Ember.computed('item', function () {
      return this.get('item.variantId') || this.get('item.id');
    }),
    buttonGaClass: 'ga-configurator-item-btn',
    actions: {
      configuratorItemClick: function configuratorItemClick() {
        // best guess is that user clicked the button?
        var isButton = true; // can't be sure that we have window.event in all browsers

        if (window.event && !window.event.target.classList.contains(this.buttonGaClass)) {
          isButton = false;
        }

        this.gtm.trackEvent({
          event: 'EventTracking',
          EventCategory: 'Konfigurator',
          EventAction: this.get('item').name,
          EventLabel: "Konfigurieren (".concat(isButton ? 'Button' : 'Bild', ")")
        });
      }
    }
  }));

  _exports.default = _default;
});