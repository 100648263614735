define("bito-shop-frontend/routes/user-confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    breadCrumb: Ember.computed(function () {
      return {
        title: this.get('intl').t('account.confirm')
      };
    }),
    titleToken: Ember.computed(function () {
      return this.get('intl').t('account.confirm');
    }),
    model: function model(params) {
      return {
        token: params.token
      };
    },
    actions: {
      onSuccess: function onSuccess() {
        var user = this.mystique.get('currentUser') || {};
        user === null || user === void 0 ? void 0 : user.reload();
      }
    }
  });

  _exports.default = _default;
});