define("bito-shop-frontend/routes/account/address/index", ["exports", "bito-shop-frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model: function model() {
      var isos = this.mystique.get('tenantConfig.countries') || [];
      var user = this.mystique.get('currentUser') || {};
      return Ember.RSVP.hash({
        currentUser: user,
        account: this.modelFor('account'),
        countries: this.store.query('country', {
          isos: isos
        })
      });
    }
  });

  _exports.default = _default;
});